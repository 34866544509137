import React from 'react'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useActiveMenu } from "react-active-menu";
import about_M2gether from "../images/about_M2gether.svg";
import about_M2getherDirectory from "../images/about_M2gether_Directory.gif";
import Seo from "../utils/seo"

import "./about.scss"

export default function About() {
    const { registerSection, registerTrigger } = useActiveMenu({
        offset: 120,
        smooth: true
    });
    return (
        <Layout>
            <Container>
                <Row>
                    <Col md={2} className="p-0">
                        <div className="sticky-menu">
                            <button className="sticky-menu-text" ref={registerTrigger("section-1")} type="button">關於我們</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-2")} type="button">从二的由來</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-3")} type="button">廟宇大全</button>
                            <button className="sticky-menu-text" ref={registerTrigger("section-4")} type="button">聯絡我們 </button>
                        </div>
                    </Col>
                    <Col md={10}>
                        <h6 ref={registerSection("section-1")}>傳統文化．創新科技．凝聚社群</h6>
                        <h2 className='about_slogan'>以傳統為基礎 以科技為輔助 <br></br>一起開創文化新體驗！</h2>

                        <section className='pb-3'>
                            <h6>關於从二</h6>
                            <p>「从二」團隊是一群對傳統節日、習俗及文化有著濃厚興趣的人組成，成員包括學者、設計師、藝術家、軟件工程師等。我們相信傳統文化是一個地方的根基，因此我們致力於透過科技的應用，為傳統文化帶來數碼革新，利用科技讓傳統文化得到更好的保護和傳承。
                            </p>
                            <p>在現代社會，傳統文化面臨著新的挑戰和機遇，我們認為科技是將傳統文化帶向未來的關鍵。因此，我們利用創新的方式，為傳統文化注入新的活力。我們計畫舉辦各種文化活動、展覽和講座等，讓人們可以更加互動地體驗，並且了解傳統文化的價值和意義。
                            </p>
                            <p>我們相信透過凝聚社群的力量，傳統文化可以得到更好的保護和傳承，也可以更好地融入現代社會的發展中 ，讓更多人認識、接觸和參與。
                            </p>
                        </section>

                        <section className='pb-3'>
                            <img src={about_M2gether} alt="Metagain" className="about_M2gether_IMG" />
                            <h6 ref={registerSection("section-2")}>从二的由來</h6>
                            <p>禮記，祭義－第一章：祭不欲數，數不敬。祭不欲疏，疏則怠，怠則忘。在中國文化中，任何傳統節日、習俗都離不開不同程度的「祭禮」，「仁」在中國儒家中心思想蘊含極廣的道德範疇。孔子把「仁」作為最高的道德原則、道德標準和道德境界。而从二从人，為元，象徵開始的意義。从人从二，為仁，从二，亦指不僅是我一個人，還有自身以外的眾人。故『从二』是象徵「眾人」的「仁」去「開始」。
                            </p>
                        </section>

                        <section className='pb-3'>
                            <img src={about_M2getherDirectory} alt="Metagain" className="about_M2gether_IMG" />
                            <h6 ref={registerSection("section-3")}>廟宇大全</h6>
                            <p>
                                提供全球各大廟宇資訊的網站，旨在讓人們更好地了解和探索傳統文化。我們收錄了各地的廟宇信息，包括奉神靈、服務、廟宇簡介、地址、地圖等。透過這個平台，人們可以方便地查找到自己感興趣的廟宇，深入了解其歷史、文化和價值。此外，也提供了相關的活動和導覽，讓人們能夠更快捷方便地查閱廟宇資料。
                            </p>
                        </section>

                        <hr></hr>
                        <section className='pb-3'>
                            <p ref={registerSection("section-4")}>若您對从二有任何查詢、媒體報導、工作職缺或業務合作的詢問，歡迎與我們聯繫！您可以透過以下方式聯絡我們：</p>
                            <p>電郵：hi@m2gether.com</p>
                            <p>
                                香港灣仔堅拿道西10號冠景樓3樓A室<br></br>
                                Unit A, 3/F, Top View Mansion, 10 Canal Road West, Hong Kong<br></br>
                            </p>

                        </section>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}


export const Head = () => (
    <Seo
        title="關於从二"
        description="「从二」團隊是一群對傳統節日、習俗及文化有著濃厚興趣的人組成，成員包括學者、設計師、藝術家、軟件工程師等。我們相信傳統文化是一個地方的根基，因此我們致力於透過科技的應用，為傳統文化帶來數碼革新，利用科技讓傳統文化得到更好的保護和傳承。"
        siteUrl="http://m2gether.com/about"
    />
)




